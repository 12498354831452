<template>
    <div class="wordslist">
        <!-- <div class="wordslist-search">
            <van-search v-model="search" placeholder="请输入搜索关键词" />
        </div> -->
        <div class="wordslist-list">
            <div
                v-for="(item, index) in articles"
                :key="index"
                class="my-card"
                style="margin-bottom: 0.5rem"
            >
                <div class="my-font-md" style="width: 100%">
                    <div
                        class="home-words-card-title"
                        @click="toWordsPage(item.articleId)"
                        style="width: 100%; font-weight: bold"
                    >
                        {{ cutContent(item.articleTitle) }}
                    </div>
                    <div class="home-words-down">
                        <span>{{ item.userInfo.userNickname }}</span>
                        <span>{{ getTime(item.articleAddtime) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <van-pagination
            style="margin-top: 1rem; margin-bottom: 1rem"
            v-model="currentPage"
            :page-count="totalPage"
            mode="simple"
            @change="changePage"
        />
    </div>
</template>

<script>
import { getBlogList } from "@/api/common";
export default {
    components: {},
    data() {
        return {
            search: "",
            currentPage: 1,
            articles: [],
            id: "",
            wordsNum: 0,
            totalPage: 0,
        };
    },
    watch: {
        $route() {
            console.log("xxx")
            this.id = this.$route.params.kind;
            this.getBlogList();
        },
    },
    computed: {},
    created() {},
    mounted() {
        this.getId();
        this.getBlogList();
    },
    methods: {
        cutContent(text) {
            // 裁剪 title
            if (text.length >= 100) {
                return text.substr(0, 100) + "···";
            } else {
                return text;
            }
        },
        getId() {
            this.id = this.$route.params.kind;
        },
        getTime(time) {
            // 格式化时间
            return new Date(time).Format("yyyy-MM-dd");
        },
        getBlogList() {
            const data = {
                kind: this.id,
                page: this.currentPage,
            };
            getBlogList(data).then((res) => {
                if (res.code === 20000) {
                    this.articles = res.data.articles;
                    this.wordsNum = res.data.totalElm;
                    this.totalPage = res.data.totalPage;
                }
            });
        },
        changePage() {
            this.getBlogList(this.currentPage);
            scrollTo(0, 0);
        },
        toWordsPage(id) {
            this.$router.push({ name: "WordsPage", params: { id: id } });
        },
    },
};
</script>

<style scoped>
.wordslist-list {
    margin-top: 0rem;
}

.home-card-flex {
    display: flex;
    flex-direction: row;
    align-items: center;

    line-height: 1.5rem;
}

.home-words-card-title {
    margin-bottom: 0.5rem;
}

.home-words-down {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
}
</style>
